.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  }
  
  * {
    /* outline: 1px solid lime; */

  }

  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    background-color: red;
    /* margin-top: 60px; */
  }

  /* Page content */
  #content {
  padding-top: 74px;

  }
.contentPadding {
  padding-top: 74px;
}
.addShadow {
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  
}
.contentPaddingForPhone {
  padding-top: 84px;
}

.text-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #FF9462 , #FF7A7A );
  ;
}
.integrationCard:hover {

 transition: all 2s ease-in 1s;
}
  
